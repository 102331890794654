import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import FaraazSiddiquiMobile from '../components/faraaz/FaraazSiddiquiMobile';
import FaraazSiddiquiDesktop from '../components/faraaz/FaraazSiddiquiDesktop';
import { Helmet } from 'react-helmet';


const ProfileFaraazSiddiqui = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>

    <Helmet>
    <meta name="robots" content="noindex"/>
    <meta charSet="utf-8" />
    <title> Faraaz Siddiqui </title>
    </Helmet>

    <section id="website-responsive" className="d-none d-md-block">
      <FaraazSiddiquiDesktop/>
    </section>

    <section id="website-responsive" className="d-block d-md-none">
      <FaraazSiddiquiMobile/>
    </section>

    </>
  );
}

export default ProfileFaraazSiddiqui;
